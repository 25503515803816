<template>
  <div>
    <header-main
        title="Сбор данных по переводу студентов из других стран"/>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <WrapperCollapse
          :initialOpen="true"
          :title="'Ответственный за заполнение сбора'"
      >
        <ContainerPersonContact
            :blocked="getBlock"
            :can-be-deleted="false"
            :contact="contact"
            :loading-contacts="savingContact"
            :saving-data="savingContact"
            @change-contact="changeContact($event,0,contact)"
            is-single
        />
      </WrapperCollapse>
    </WrapperPreviewCategory>

    <div class="d-flex block-control">
      <div class="d-flex block-file">
        <v-file-input
            class="field__file"
            id="field__file"
            clearable
            danse
            :accept="['text/xml']"
            label="Загрузите XML"
        />
        <v-btn
            @click="uploadFile()"
            class="blue__v-btn mb-2 ml-2"
        >
          Загрузить
        </v-btn>
        <v-btn
            @click="downloadLkFile()"
            class="blue__v-btn mb-2 ml-2"
        >
          Скачать
        </v-btn>
      </div>
      <v-btn
          :disabled="getBlock"
          @click="openDialog('add')"
          class="blue__v-btn mb-2"
      >
        Создать
      </v-btn>
    </div>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <GridTable
          :data-rows="tableData"
          :headers="tableHeaders.massive"
          :headers-for-rows="tableHeaders.massive"
          :loading-data="loadingData"
          :num-cols="tableHeaders.countCells"
          max-table-height="700px"
          style="width:4000px"
      >
        <template #actions="{row}">
          <div class="d-flex align-center justify-center flex-row">
            <v-btn v-if="row.prikazFileId"
                   :disabled="getBlock"
                   :loading="loadingFileToServer.fileType === file.fileType && loadingFileToServer.value"
                   @click.stop="downloadFile(row.prikazFileId, '', true)"
                   icon>
              <v-icon color="primary" size="24">mdi-file-outline</v-icon>
            </v-btn>
            <v-btn :disabled="getBlock" :loading="loadingEdit.id === row.id && loadingEdit.value"
                   @click.stop="openDialog('edit', row)"
                   class="mx-1"
                   icon>
              <v-icon color="primary" size="24">mdi-pencil</v-icon>
            </v-btn>
            <v-btn :disabled="getBlock"
                   :loading="loadingDelete.id === row.id && loadingDelete.value"
                   @click.stop="deleteRow(row)"
                   icon>
              <v-icon color="primary" size="24">mdi-delete</v-icon>
            </v-btn>
          </div>
        </template>
      </GridTable>
    </WrapperPreviewCategory>
    <universal-dialog
        ref="dialog"
        :data-for-dialog="dataForDialog"
        :headers-for-dialog="tableHeaders.dialog"
        :spr-data="sprDataForDialog"
        @fetch-new-data="changeData($event)"
        @dialog-emit="filterFunc($event)"
        :loading="savingData"
        @closing-dialog="clearDataForDialog()"
    >
      <template #title>
        <h2>Добавить запись</h2>
      </template>
      <template #actions="{close, sendData, loading, validSolo}">
        <div class="d-flex flex-row align-center justify-space-between" style="width: 100%">
          <v-btn
              class='white__v-btn'
              @click="close()"
          >
            Отменить
          </v-btn>
          <v-btn
              class='blue__v-btn'
              @click="sendData()"
              :loading="loading"
              :disabled="!validSolo"
          >
            Сохранить
          </v-btn>
        </div>
      </template>
    </universal-dialog>
  </div>
</template>

<script>
import {mapSpr} from "@/utils/helpers";
import request from "@/services/request";
import {mapActions, mapGetters} from 'vuex';
import HeaderMain from "@/components/headers/HeaderMain";
import WrapperPreviewCategory from '@/components/wrappers/WrapperPreviewCategory';
import WrapperCollapse from '@/components/wrappers/WrapperCollapse';
import ContainerPersonContact from "@/components/contacts/ContainerPersonContact";
import GridTable from "@/components/GridTable/GridTable";
import Sbor3TableHeaders from "@/tableJson/sbor3TableHeaders";
import universalDialog from "@/components/Dialogs/universalDialog";
import {setFileFunctions} from "@/mixins/fileFucntions";

export default {
  name: "LK",
  mixins: [setFileFunctions],
  components: {
    GridTable,
    HeaderMain,
    WrapperPreviewCategory,
    WrapperCollapse,
    ContainerPersonContact,
    universalDialog
  },
  data() {
    return {
      contact: {
        fio: '',
        email: '',
        position: '',
        workPhoneNumber: '',
        personalPhoneNumber: '',
        extensionNumber: '',
        personType: 'HEAD'
      },
      loadingData: false,
      savingData: false,
      loadingEdit: {value: false, id: null},
      loadingDelete: {value: false, id: null},
      tableData: [],
      tableHeaders: Sbor3TableHeaders,
      loadingContacts: false,
      savingContact: false,
      loadingContact: false,
      errorSaving: null,
      loadingBlockEnterData: false,
      loadingBlockEnterWithoutData: false,
      downloadingFileForPrint: false,
      sprOrg: [],
      block: false,
      loadingBlockStatus: false,
      sprOrgLoading: false,
      sprOrgError: false,
      sprSpec: [],
      sprSpecLoading: false,
      sprSpecError: false,
      sprOksm: [],
      sprOksmLoading: false,
      sprOksmError: false,
      sprUsl: [],
      sprUslLoading: false,
      sprUslError: false,
      sprFo: [],
      sprFoLoading: false,
      sprFoError: false,
      sprFf: [],
      sprFfLoading: false,
      sprFfError: false,
      dataForDialog: null,
      editRowActive: false,
    }
  },
  created() {

    if (this.authData && this.orgID) {
      this.getUserInfo();
      this.getBlockStatus();
    }
    this.getContacts();
    this.getData();
    this.loadSprOrg();
    this.loadSprSpec();
    this.loadSprOksm();
    this.loadSprUsl();
    this.loadSprFf();
    this.loadSprFo();
  },
  computed: {
    ...mapGetters('auth', {authData: 'authData'}),
    ...mapGetters('auth', {get_blockedEnterData: 'get_blockedEnterData'}),
    ...mapGetters('auth', {authToken: 'authToken'}),
    ...mapGetters('auth', {authStatus: 'authStatus'}),
    ...mapGetters('checkFilled', {get_check_filled: 'get_check_filled'}),
    ...mapGetters('auth', {get_replace_id_admin: 'get_replace_id_admin'}),
    ...mapGetters('auth', {get_blockedAll: 'get_blockedAll'}),
    ...mapGetters('auth', {getAuthUserData: 'getAuthUserData'}),
    ...mapGetters('auth', {getAuthUserDataLoading: 'getAuthUserDataLoading'}),
    ...mapGetters('auth', {getAuthUserDataError: 'getAuthUserDataError'}),
    ...mapGetters('keycloak', {getAuthData: 'getAuthData'}),

    getBlock() {
      return this.block || this.loadingBlockStatus;
    },
    orgID() {
      // if (this.$route.query?.orgID) {
      //   return this.$route.query.orgID;
      // }
      // return Number.parseInt(this.authData?.orgID ?? this.authData?.family_name);
      const orgId = this.getAuthData.idTokenParsed.orgID
      return orgId
    },
    getOrgFounderID() {
      return Number.parseInt(this.getAuthUserData?.founderId);
    },
    sprDataForDialog() {
      let ret = {
        sprOrg: this.getSprOrg(),
        sprSpec: this.getSprSpec(),
        sprOksm: this.getSprOksm(),
        sprFo: this.getSprFo(),
        sprFf: this.getSprFf(),
        sprOksmFiltered: this.getSprOksmFiltered(),
        sprUsl: this.getSprUsl(),
        sprBool: {
          data: [
            {
              text: 'Да',
              value: true
            },
            {
              text: 'Нет',
              value: false
            }
          ]
        },
        sprPrikazType: [
          {
            text: 'о зачислении в порядке перевода',
            value: 'TRANSFER'
          },
          {
            text: 'о допуске к занятиям',
            value: 'ASSIGN'
          },
        ],
        sprStatus: {
          data: [
            {
              text: 'Рассмотрен',
              value: 'ACCEPT'
            },
            {
              text: 'Не рассмотрен',
              value: 'DONE'
            },
            {
              text: 'На рассмотрении',
              value: 'INWORK'
            },
            {
              text: 'Ведется консультация и подбор программы',
              value: 'CONS_AND_PODBOR'
            },
            {
              text: 'Заявка передана на ОП - оформляется перевод',
              value: 'ZAYAV_TO_OP'
            },
            {
              text: 'Ожидаем заявку. Консультация предоставлена',
              value: 'WAIT_ZAYAV'
            }
          ]
        },
        sprKurs: {
          data: [
            {
              text: 'прием 2022',
              value: 'прием 2022'
            },
            {
              text: '1',
              value: '1'
            },
            {
              text: '2',
              value: '2'
            },
            {
              text: '3',
              value: '3'
            },
            {
              text: '4',
              value: '4'
            },
            {
              text: '5',
              value: '5'
            },
            {
              text: '6',
              value: '6'
            },
          ]
        }
      }
      return ret
    },
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    ...mapActions('checkFilled', {fetchCheckFilled: 'fetchCheckFilled'}),
    ...mapActions('auth', {blockEnterData: 'blockEnterData'}),
    ...mapActions('auth', {getUserInfo: 'getUserInfo'}),
    ...mapActions('auth', {blockOrganization: 'blockOrganization'}),
    getQuery(item) {
      return {
        path: item.href,
        query: {orgID: this.$route.query.orgID}
      }
    },
    async uploadFile() { // загрузка файла 
      if (document.querySelector("#field__file").files.length) {
        const fd = new FormData();
        fd.append("file", document.querySelector("#field__file").files[0]);
        let res = await request({
          endpoint: '/api/sborvuzpervod/xml',
          method: 'put',
          headers: {"Content-Type": "multipart/form-data"},
          body: fd
        })
        if (res.status === 200) {
          window.location.reload();
        } else {
          console.log(res.error)
        }
      }
    },
    async getBlockStatus() {
      // this.loadingBlockStatus = true
      // try {
      //   const res = await request({
      //     endpoint: `/api/sbor2/block/${this.orgID}`,
      //     method: 'get'
      //   })
      //   if (res.status === 200) {
      //     if (res.data) {
      //       this.block = res.data.block
      //     }
      //   }
      //   this.loadingBlockStatus = false
      // }catch (e) {
      //   console.error('error get block status', e)
      //   this.loadingBlockStatus = false
      // }
    },
    async blockOrg() {
      // this.loadingBlockStatus = true
      // try {
      //   const res = await request({
      //     endpoint: `/api/sbor2/block/${this.orgID}/${this.block ? 'unblock' : 'block'}`,
      //     method: 'get'
      //   })
      //   if (res.status === 200) {
      //     if (res.data) {
      //       this.block = res.data.block
      //     }
      //   }
      //   this.loadingBlockStatus = false
      // }catch (e) {
      //   console.error('error to  block org', e)
      //   this.loadingBlockStatus = false
      // }
    },
    async loadSprOrg() { //  загрузка списка организаций
      this.sprOrgLoading = true
      const res = await request({
        endpoint: `/api/spr/org/${this.orgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprOrg = res.data
        }
      }
      this.sprOrgLoading = false
    },
    async loadSprSpec() { //  загрузка списка специальностей
      this.sprSpecLoading = true
      const res = await request({
        endpoint: `/api/spr/spec`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprSpec = res.data
        }
      }
      this.sprSpecLoading = false
    },
    async loadSprOksm() { //  загрузка списка оксм
      this.sprOksmLoading = true
      const res = await request({
        endpoint: `/api/spr/oksm`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprOksm = res.data
        }
      }
      this.sprOksmLoading = false
    },
    async loadSprUsl() { //  загрузка списка усл
      this.sprUslLoading = true
      const res = await request({
        endpoint: `/api/spr/usl`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprUsl = res.data
        }
      }
      this.sprUslLoading = false
    },
    async loadSprFo() { //  загрузка списка fo
      this.sprFoLoading = true
      const res = await request({
        endpoint: `/api/spr/getFo`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprFo = res.data
        }
      }
      this.sprFoLoading = false
    },
    async loadSprFf() { //  загрузка списка ff
      this.sprFoLoading = true
      const res = await request({
        endpoint: `/api/spr/getFf`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.sprFf = res.data
        }
      }
      this.sprFoLoading = false
    },
    async clickBlockEnterData() {
      // this.loadingContacts = true;
      // const res = await request({
      //   endpoint: `/api/sbor2/organization/${this.orgID}/block`,
      //   method: 'get'
      // })
      // if (res.status === 200) {
      //   this.blockOrganization(res.data)
      // }
      // this.loadingContacts = false;
    },
    async getData() { //  получение данных для таблицы 
      this.loadingData = true;
      const res = await request({
        endpoint: `/api/sborvuzpervod/${this.orgID}`,
        method: 'get'
      })
      console.log("GET DATA",res.data)
      if (res.status === 200) {
        res.data.forEach(row => {
          row.grazhdanstvoText = row.grazhdanstvo?.name // достаем  из гражданство имя и кладем в отдельную перемнную для вывода в таблице
          row.grazhdanstvo = row.grazhdanstvo?.oksmId // для модалки перемнной оставляем только id
          row.countryPlanPervodText = row.countryPlanPervod?.name
          row.countryPlanPervod = row.countryPlanPervod?.oksmId
          row.uslText = row.usl?.uslName
          row.usl = row.usl?.id
          row.orgPlanPervodText = row.orgPlanPervod?.orgName
          row.orgPlanPervod = row.orgPlanPervod?.id
          row.specPervodText = row.specPervod !== null ? (row.specPervod?.specCode + ' ' + row.specPervod?.specName) : "Не указано" // для таблицы здесь объединяем поля
          row.specPervod = row.specPervod?.id
          row.emailPhone = (row.email === null ? '' : row.email) + ' ' + (row.phone === null ? '' : row.phone) // для таблицы здесь объединяем поля
          row.birthDateText = row.birthDate ? row.birthDate.split('-').reverse().join('.') : '-' // в читаемый вид приводим дату
          row.reportDateText = row.reportDate ? row.reportDate.split('-').reverse().join('.') : '-' // в читаемый вид приводим дату
          row.org = row.org?.orgName
          this.tableData.push(row)
        })
      }
      this.loadingData = false;
    },
    async changeData(row) {
      let isDublicatedData = false
      if (!this.editRowActive) {
        this.tableData.map(data => {
          if (data.birthDate === row.birthDate
              && data.birthDate === row.birthDate
              && data.fio === row.fio
              && data.specPervod === row.specPervod) {
            isDublicatedData = true
          }
        })
      }
      if (isDublicatedData) {
        this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Запись уже существует',
          timeout: 5000
        })
      } else {
        // создание или редактирование если нет id то создание, если есть то редактирование
        this.savingData = true;
        this.sprOksm.forEach(tempOksm => { // из списка всех оксм ищем гле id = id гражданства и присваемем в гражданство объект вместо id
          if (tempOksm.oksmId === row.grazhdanstvo) {
            row.grazhdanstvo = tempOksm
          }
          if (tempOksm.oksmId === row.countryPlanPervod) {
            row.countryPlanPervod = tempOksm
          }
        })
        this.sprUsl.forEach(tempUsl => {
          if (tempUsl.id === row.usl) {
            row.usl = tempUsl
          }
        })
        this.sprOrg.forEach(tempOrg => {
          if (tempOrg.id === row.orgPlanPervod) {
            row.orgPlanPervod = tempOrg
          }
        })
        this.sprSpec.forEach(tempSpec => {
          if (tempSpec.specId === row.specPervod) {
            row.specPervod = {
              id: tempSpec.specId,
              specCode: tempSpec.specCode,
              specName: tempSpec.specName
            }
          }
        })
        row.org = { // текущая организация (пользователь который залогинен и заполняетформу)
          "id": this.orgID,
          "orgFullName": this.authData.name,
          "orgName": this.authData.given_name
        }
        if (typeof row.otkaz === 'object') {
          row.otkaz = row.otkaz.value
        }
        row.foId = row.foId ?  {id: row.foId} : null
        row.ffId = row.ffId ? {id: row.ffId} : null
        //row.address = ''
        // console.log("DATA TO SEND", row)
        //row.prikazAvailable = row.prikazAvailable === "Да"
        //row.otkaz = row.otkaz === "Да"

        // row.countryPribitia = row.countryPlanPervod
        const res = await request({
          endpoint: `/api/sborvuzpervod/set`,
          method: 'put',
          body: row
        })
        // console.log("DATA AFTER SEND", row, res);
        if (res.status === 200) {
          const tempData = res.data
          tempData.grazhdanstvoText = tempData.grazhdanstvo?.name
          tempData.grazhdanstvo = tempData.grazhdanstvo?.oksmId
          tempData.countryPlanPervodText = tempData.countryPlanPervod?.name
          tempData.countryPlanPervod = tempData.countryPlanPervod?.oksmId
          tempData.uslText = tempData.usl?.uslName
          tempData.usl = tempData.usl?.id
          tempData.orgPlanPervodText = tempData.orgPlanPervod?.orgName
          tempData.orgPlanPervod = tempData.orgPlanPervod?.id
          tempData.specPervodText = tempData.specPervod !== null ? (tempData.specPervod?.specCode + ' ' + tempData.specPervod?.specName) : "Не указано"
          tempData.specPervod = tempData.specPervod?.id
          tempData.emailPhone = (tempData.email === null ? '' : tempData.email) + ' ' + (tempData.phone === null ? '' : tempData.phone)
          tempData.birthDateText = tempData.birthDate
          tempData.reportDateText = tempData.reportDate
          tempData.org = tempData.org?.orgName
          const index = this.tableData.findIndex(el => el.id === row.id)
          //console.log(tempData, index);
          if (row.id) {
            this.tableData.splice(index, 1, tempData)
          } else {
            this.tableData.push(res.data);
          }
          setTimeout(() => {
            this.$refs.dialog.closeDialog();
            this.savingData = false;
          }, 2000);
          this.editRowActive = false
        } else {
          this.savingData = false;
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка сохранения данных ',
            timeout: 5000
          })
        }
      }
    },
    async downloadLkFile() {
      const res = await request({
        endpoint: `/api/sborvuzpervod/xml/${this.orgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          var filename = "file.xml";
          var pom = document.createElement('a');
          var bb = new Blob([res.data], {type: 'text/plain'});
          pom.setAttribute('href', window.URL.createObjectURL(bb));
          pom.setAttribute('download', filename);
          pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
          pom.draggable = true;
          pom.classList.add('dragout');
          pom.click();
          pom.remove();
        }
      }
    },
    async getContacts() { // получение данных о том кто заполнял таблицу (информация для формы)
      this.loadingContacts = true;
      const res = await request({
        endpoint: `/api/sborvuzpervod/contact/${this.orgID}`,
        method: 'get'
      })
      if (res.status === 200) {
        if (res.data) {
          this.contact = res.data
          await this.fetchCheckFilled({headContact: this.contact, contacts: this.contact})
        }
      }
      this.loadingContacts = false;
    },
    async changeContact(obj) { // отправка на бэк данных о том кто заполнял таблицу
      this.savingContact = true;
      const res = await request({
        endpoint: `/api/sborvuzpervod/contact/set`,
        method: 'post',
        body: {
          ...obj,
          orgId: this.orgID
        },
      })
      if (res.status === 200) {
        this.contact = res.data;
        setTimeout(() => {
          this.savingContact = false;
        }, 2000);
      } else {
        this.savingContact = false;
        await this.addNotification({
          notificationStatus: 'error',
          notificationMessage: 'Ошибка сохранения данных ответственного ',
          timeout: 5000
        })
      }
    },
    async openDialog(type = 'action', row = null) {
      if (type === 'edit') {
        this.editRowActive = true
        this.dataForDialog = {...row, foId: row.foId?.id, ffId: row.ffId?.id}
        await this.$refs.dialog.openDialog();
      } else {
        this.editRowActive = false
        this.dataForDialog = {
          otkaz: {
            text: "Нет",
            value: false
          }
        }
        await this.$refs.dialog.openDialog();
      }
    },
    clearDataForDialog() {
      this.dataForDialog = null;
      this.editRowActive = false
    },
    getSprOrg() { // переработка списка организаций в список для работы в селекте модального окна
      let data = [];
      if (!this.sprOrgLoading && !this.sprOrgError) {
        data = mapSpr(this.sprOrg, 'id', (el) => {
          return el.orgFullName
        })
      }
      return {data: data, loading: this.sprOrgLoading, error: this.sprOrgError}
    },
    getSprSpec() {
      let data = [];
      if (!this.sprSpecLoading && !this.sprSpecError) {
        data = mapSpr(this.sprSpec, 'specId', (el) => {
          return el.specCode + " - " + el.specName
        })
      }
      return {data: data, loading: this.sprSpecLoading, error: this.sprSpecError}
    },
    getSprOksm() {
      let data = [];
      if (!this.sprOksmLoading && !this.sprOksmError) {
        data = mapSpr(this.sprOksm, 'oksmId', (el) => {
          return el.name
        }).sort((a, b) => {
          if (a.value === 185) return -1;
          if (a.value === 232 && b.value !== 185) return -1;
          if (a.value === 254 && b.value !== 185) return -1;
          if (a.value === 255 && b.value !== 185) return -1;
          if (a.value === 256 && b.value !== 185) return -1;
          if (a.value === 257 && b.value !== 185) return -1;
          return 0
        })
      }
      return {data: data, loading: this.sprOksmLoading, error: this.sprOksmError}
    },
    getSprOksmFiltered() {
      let data = [];
      if (!this.sprOksmLoading && !this.sprOksmError) {
        data = mapSpr(this.sprOksm, 'oksmId', (el) => {
          return el.name
        }).filter(oksm => oksm.value !== 185).sort((a, b) => {
          if (a.value === 185) return -1;
          if (a.value === 232 && b.value !== 185) return -1;
          if (a.value === 254 && b.value !== 185) return -1;
          if (a.value === 255 && b.value !== 185) return -1;
          if (a.value === 256 && b.value !== 185) return -1;
          if (a.value === 257 && b.value !== 185) return -1;
          return 0
        })
      }
      return {data: data, loading: this.sprOksmLoading, error: this.sprOksmError}
    },
    getSprUsl() { // переработка списка усл в список для работы в селекте модального окна
      let data = [];
      if (!this.sprUslLoading && !this.sprUslError) {
        data = mapSpr(this.sprUsl, 'id', (el) => {
          return el.uslName
        })
      }
      return {data: data, loading: this.sprUslLoading, error: this.sprUslError}
    },
    getSprFo() {
      let data = [];
      if (!this.sprFoLoading && !this.sprFoError) {
        data = mapSpr(this.sprFo.filter(el => (el.id < 4)), 'id', (el) => {
          return el.name
        })
      }
      return {data: data, loading: this.sprFoLoading, error: this.sprFoError}
    },
    getSprFf() {
      let data = [];
      if (!this.sprFfLoading && !this.sprFfError) {
        data = mapSpr(this.sprFf, 'id', (el) => {
          return el.name
        })
      }
      return {data: data, loading: this.sprFfLoading, error: this.sprFfError}
    },
    async deleteRow(row) { // удаление записи
      const agree = confirm('Удалить запись?')
      if (agree) {
        const res = await request({
          endpoint: `/api/sborvuzpervod/delete?id=${row.id}`,
          method: 'DELETE',
          // body: {
          //   ...row
          // },
        })
        console.log(row, res);
        if (res.status === 200) {
          // const tempData = res.data
          // tempData.grazhdanstvoText = tempData.grazhdanstvo?.name
          // tempData.grazhdanstvo = tempData.grazhdanstvo?.oksmId
          // tempData.countryPlanPervodText = tempData.countryPlanPervod?.name
          // tempData.countryPlanPervod = tempData.countryPlanPervod?.oksmId
          // tempData.uslText = tempData.usl?.uslName
          // tempData.usl = tempData.usl?.id
          // tempData.orgPlanPervodText = tempData.orgPlanPervod?.orgName
          // tempData.orgPlanPervod = tempData.orgPlanPervod?.id
          // tempData.specPervodText = tempData.specPervod !== null ? (tempData.specPervod?.specCode + ' ' + tempData.specPervod?.specName) : "Не указано"
          // tempData.specPervod = tempData.specPervod?.id
          // tempData.emailPhone = (tempData.email === null ? '' : tempData.email) + ' ' + (tempData.phone === null ? '' : tempData.phone)
          // tempData.birthDateText = tempData.birthDate
          // tempData.reportDateText = tempData.reportDate
          // tempData.org = tempData.org?.orgName
          const index = this.tableData.findIndex(el => el.id === row.id)
          //console.log(tempData, index);
          this.tableData.splice(index, 1)
          this.loadingDelete = {value: null, id: row.id};
        } else {
          this.loadingDelete = {value: null, id: row.id};
          await this.addNotification({
            notificationStatus: 'error',
            notificationMessage: 'Ошибка удаления',
            timeout: 3000
          });
        }
      }
    },
  }
}
</script>

<style lang="sass">
.section-preview_lk
  background: #fff
  border-radius: 6px
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.06)
  //border: 1px solid #cdd1d4
  margin-bottom: 20px
  overflow-x: auto


  .lk-list_flex_start
    justify-content: flex-start

.block-control
  align-items: center
  justify-content: space-between

.field__file
  width: 300px

.block-file
  align-items: center
</style>