<template>
    <WrapperPreviewCategory customClass="section-preview_lk">
      <v-row>
        <v-col class=" d-flex justify-end">
          <card-prin-document-btn
              link="/api/sborvuzpervod/getXlsx"
              name="Выгрузка"
              class="mr-2 mb-2"
              file-type="xlsx"
              file-type-name=".xlsx"
              type="blue"
          >
          </card-prin-document-btn>
        </v-col>
      </v-row>
    </WrapperPreviewCategory>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex';
    import request from "@/services/request";
    import {saveFile} from "@/utils/saveFile";
    import WrapperPreviewCategory from "@/components/wrappers/WrapperPreviewCategory";
    import CardPrinDocumentBtn from "@/components/cardPrinDocumentBtn";

    export default {
        name: "LKAdmin",
        components: {CardPrinDocumentBtn, WrapperPreviewCategory},
        data: () => ({
            uploadedFileLoading: false,
            exportFileLoading: false,
            loadingBlockEnterData: false,
            orgFilter: null,
            organizations: [],
          loadingData: false,
        }),
        computed: {
            ...mapGetters('spr', {getSprDataOrg: 'get_spr_data_org'}),
            getHeaders() {
                return [
                    {text: 'ИД', align: 'center', filterable: true, value: 'orgId'},
                    {text: 'Наименование ораганизации', filterable: true, value: 'organizationName'},
                    {text: 'Статус', filterable: true},
                    {text: 'Действия', filterable: false},
                ]
            },
            getOrgList() {
              if (this.organizations !== null) {
                return  this.organizations.filter((el) => (el?.orgId !== null))
                    .sort((a) => {
                      if (a.blocked === false )
                      return 1
                      else return -1
                    })
                    .sort((a,b) => {
                      if ((a.orgId - b.orgID) > 0)
                        return -1
                      else
                        return 1
                    })
                    ;
              }
              else
               return [];
            }
        },
        created() {
            this.loadOrgList();
        },
        methods: {
            ...mapActions('notifications', {addNotification: 'addNotification'}),
            filterOrg(value, orgFilter, item) {
              // console.log(value,orgFilter,item)
                return orgFilter != null && item.orgName.toLowerCase().indexOf(orgFilter.toLowerCase()) !== -1
            },
            async getUploadedFile(file) {
                this.uploadedFileLoading = true;
                const response = await request({
                    endpoint: `/api/file/scan/download?fileID=${file.fileID}`,
                    method: 'GET',
                });
                this.uploadedFileLoading = false;
                saveFile(response.data, false);
            },
            async getExportFile() {
                this.exportFileLoading = true;
                const response = await request({
                    endpoint: `/api/sbor2/export`,
                    method: 'GET',
                });
                this.exportFileLoading = false;
                saveFile(response.data, false);
            },
            getStatus(userStatus) {
                switch (userStatus) {
                    case 'ACTIVE':
                        return "Открыт";
                    case 'BLOCK_WITHOUT_DATA':
                        return "Блок, отсутствие данных";
                    case 'BLOCKED_FOR_PRINT':
                        return "Блок, ввод закончен";
                    default:
                        return "Ошибка";
                }
            },
            async loadOrgList() {
    
            },
            async clickUnblock(item) {
                this.loadingBlockEnterData = true;
                const agree = confirm(`${item.block ? 'Разблокировать': 'блокировать'} ввод?`);
                if (agree) {
                    const res = await request({
                        endpoint: `/api/sbor2/block/${item.id}/${item.blocked ? 'unblock': 'block'}`,
                        method: 'get',
                        responseType: 'blob'
                    });
                    if (res.status === 200) {
                        await this.addNotification({
                            notificationStatus: 'success',
                            notificationMessage: `Организация ${item.blocked ? 'разблокирована': 'заблокирована'}`,
                            timeout: 5000
                        });
                        item.blocked = res.data.block;
                    } else {
                        await this.addNotification({
                            notificationStatus: 'error',
                            notificationMessage: 'Ошибка блокировки данных',
                            timeout: 5000
                        })
                    }
                }
                this.loadingBlockEnterData = false;
            }
        },
    }
</script>

<style lang="sass" scoped>
    .org-card-title
        display: flex
        flex-direction: row

        .org-card
            display: flex
            flex-direction: row
</style>