export default {
    countCells: 37,
    massive: [ 
        {
            dataField: 'fio', 
            cols: 2, 
            rows: 1, 
            text: 'ФИО', 
            order: 1,
            variable: true,
        },
        {
            dataField: 'emailPhone',
            cols: 3,
            rows: 1,
            text: 'Контактный телефон и адрес электронной почты',
            order: 1,
            variable: true,
        },
        {
            dataField: 'grazhdanstvoText',
            cols: 1,
            rows: 1,
            text: 'Гражданство',
            order: 1,
            variable: true,
        },
        {
            dataField: 'grazhdanstvoRf',
            cols: 1,
            rows: 1,
            text: 'Наличие российского гражданства',
            order: 1,
            variable: true,
            template: (row) => { 
                return row['grazhdanstvoRf'] ? "Да" : "Нет";
            }
        },
        {
            dataField: 'birthDateText',
            cols: 1,
            rows: 1,
            text: 'Дата рождения',
            order: 1,
            variable: true,
        },
        {
            dataField: 'reportDateText',
            cols: 1,
            rows: 1,
            text: 'Дата обращения о переводе или о поступлении в российскую образовательную организацию',
            order: 1,
            variable: true,
        },
        {
            dataField: 'countryPlanPervodText',
            cols: 2,
            rows: 1,
            text: 'Страна, из которой планируется перевод',
            order: 1,
            variable: true,
        },
        {
            dataField: 'city',
            cols: 2,
            rows: 1,
            text: 'Город фактического проживания на территории Российской Федерации',
            order: 1,
            variable: true,
        },
        {
            dataField: 'orgName',
            cols: 2,
            rows: 1,
            text: 'Наименование ОО, в которой кандидат обучается в настоящее время за рубежом',
            order: 1,
            variable: true,
        },
        {
            dataField: 'spec',
            cols: 2,
            rows: 1,
            text: 'Код и наименование направления подготовки',
            order: 1,
            variable: true,
        },
        {
            dataField: 'uslText',
            cols: 1,
            rows: 1,
            text: 'Уровень образования',
            order: 1,
            variable: true,
        },
        {
            dataField: 'kurs',
            cols: 1,
            rows: 1,
            text: 'Курс обучения',
            order: 1,
            variable: true,
        },
        {
            dataField: 'orgPlanPervodText',
            cols: 2,
            rows: 1,
            text: 'Образовательная организация куда планируется перевод / поступление',
            order: 1,
            variable: true,
        },
        {
            dataField: 'specPervodText',
            cols: 2,
            rows: 1,
            text: 'Код и наименование направления подготовки при переводе/поступлении',
            order: 1,
            variable: true,
        },
        {
            dataField: 'potrebnostObjezhitia',
            cols: 1,
            rows: 1,
            text: 'Потребность в общежитии (да/нет)',
            order: 1,
            variable: true,
            template: (row) => {
                return row['potrebnostObjezhitia'] ? "Да" : "Нет";
            }
        },
        {
            dataField: 'status',
            cols: 1,
            rows: 1,
            text: 'Статус рассмотрения документов/работы со студентом',
            order: 1,
            variable: true,
            template: (row) => {
                switch (row['status']) {
                    case 'ACCEPT':
                        return 'Рассмотрен';
                    case 'DONE':
                        return 'Не рассмотрен';
                    case 'INWORK':
                        return 'На рассмотрении';
                    case 'CONS_AND_PODBOR':
                        return 'Ведется консультация и подбор программы';
                    case 'ZAYAV_TO_OP':
                        return 'Заявка передана на ОП - оформляется перевод';
                    case 'WAIT_ZAYAV':
                        return 'Ожидаем заявку. Консультация предоставлена';
                }
                return '';
            }
        },
        {
            dataField: 'prikazAvailable',
            cols: 1,
            rows: 1,
            text: 'Наличие приказа о зачислении/переводе',
            order: 1,
            variable: true,
            template: (row) => {
                return row['prikazAvailable'] ? "Да" : "Нет";
            }
        },
        {
            dataField: 'prikazNumber',
            cols: 1,
            rows: 1,
            text: 'Номер приказа о зачислении/переводе',
            order: 1,
            variable: true,
        },
        {
            dataField: 'prikazDate',
            cols: 1,
            rows: 1,
            text: 'Дата приказа о зачислении/переводе',
            order: 1,
            variable: true,
        },        {
            dataField: 'prikaz',
            cols: 1,
            rows: 1,
            text: 'Примечание к приказу о зачислении/переводе',
            order: 1,
            variable: true,
        },
        {
            dataField: 'comment',
            cols: 1,
            rows: 1,
            text: 'Примечание',
            order: 1,
            variable: true,
        },
        {
            dataField: 'foId',
            cols: 2,
            rows: 1,
            text: 'Форма обучения',
            order: 1,
            variable: true,
            template: (row) => row.foId?.name
        },
        {
            dataField: 'ffId',
            cols: 2,
            rows: 1,
            text: 'Форма финансирования',
            order: 1,
            variable: true,
            template: (row) => row.ffId?.name
        },
        {
            dataField: 'fullCompensation',
            cols: 1,
            rows: 1,
            text: 'Полная компенсация стоимости обучения за счет организации',
            order: 1,
            variable: true,
            template: (row) => {
                return row['fullCompensation'] ? "Да" : "Нет";
            }
        },
        {
            dataField: 'otkaz',
            cols: 1,
            rows: 1,
            text: 'Отказ от перевода',
            order: 1,
            variable: true,
            template: (row) => {
                return row['otkaz'] ? "Да" : "Нет";
            }
        },
        {
            dataField: 'actions',
            cols: 1,
            rows: 1,
            text: 'Действия',
            order: 1,
            variable: true,
        },
    ],
    dialog: [ 
        {
            text: "ФИО *",
            dataField: "fio", 
            typeVar: "string", 
            rules: 3,
            order: 0,
        },
        {
            text: "Контактный телефон",
            dataField: "phone",
            typeVar: "string",
            rules: 0,
            order: 1,
        },
        {
            text: "Адрес электронной почты",
            dataField: "email",
            typeVar: "string",
            rules: 0,
            order: 2,
        },
        {
            text: 'Гражданство *',
            dataField: "grazhdanstvo",
            variableName: 'grazhdanstvo', 
            sprDataName: 'sprOksm', 
            typeVar: "selector", 
            rules: 'customRules', 
            customsRulesArray: [ 
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 3,
        },
        {
            text: 'Наличие российского гражданства *',
            dataField: "grazhdanstvoRf",
            variableName: 'grazhdanstvoRf',
            sprDataName: 'sprBool',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 4,
        },
        {
            text: "Дата рождения * ",
            dataField: "birthDate",
            typeVar: "date",
            rules: 3,
            order: 5,
        },
        {
            text: "Дата обращения о переводе или о поступлении в российскую образовательную организацию *",
            dataField: "reportDate",
            typeVar: "date",
            rules: 3,
            order: 7,
        },
        {
            text: "Страна, из которой планируется перевод *",
            dataField: "countryPlanPervod",
            variableName: 'countryPlanPervod',
            sprDataName: 'sprOksmFiltered',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 8,
        },
        {
            text: "Город фактического проживания на территории Российской Федерации *",
            dataField: "city",
            typeVar: "string",
            rules: 3,
            order: 9,
        },
        {
            text: "Наименование ОО, в которой кандидат обучается в настоящее время за рубежом *",
            dataField: "orgName",
            typeVar: "string",
            rules: 3,
            order: 10,
        },
        {
            text: "Код и наименование направления подготовки *",
            dataField: "spec",
            typeVar: "string",
            rules: 3,
            order: 11,
        },
        {
            text: "Уровень образования *",
            dataField: "usl",
            variableName: 'usl',
            sprDataName: 'sprUsl',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 12,
        },
        {
            text: "Код и наименование направления подготовки при переводе/поступлении *",
            dataField: "specPervod",
            variableName: 'specPervod',
            sprDataName: 'sprSpec',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 13,
        },
        {
            text: "Курс обучения *",
            dataField: "kurs",
            variableName: 'kurs',
            sprDataName: 'sprKurs',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 14,
        },
        {
            text: "Образовательная организация куда планируется перевод / поступление	*",
            dataField: "orgPlanPervod",
            variableName: 'orgPlanPervod',
            sprDataName: 'sprOrg',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 15,
        },
        {
            text: 'Потребность в общежитии *',
            dataField: "potrebnostObjezhitia",
            variableName: 'potrebnostObjezhitia',
            sprDataName: 'sprBool',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 16,
        },
        {
            text: 'Статус рассмотрения документов/работы со студентом *',
            dataField: "status",
            variableName: 'status',
            sprDataName: 'sprStatus',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 17,
        },
        {
            text: "Наличие приказа о зачислении/переводе",
            dataField: "prikazAvailable",
            variableName: 'prikazAvailable',
            sprDataName: 'sprBool',
            typeVar: "selector",
            rules: 'customRules',
            customsRulesArray: [
                v => [true, false].includes(v) || 'Значение должно быть выбрано'
            ],
            order: 18,
        },
        {
            text: "Номер приказа о зачислении/переводе *",
            dataField: "prikazNumber",
            typeVar: "string",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 3,
            order: 19,
        },
        {
            text: "Дата приказа о зачислении/переводе *",
            dataField: "prikazDate",
            typeVar: "date",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 20,
        },
        {
            text: "Тип приказа о зачислении/переводе *",
            dataField: "prikazType",
            variableName: 'prikazType',
            sprDataName: 'sprPrikazType',
            typeVar: "selector",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 21,
        },

        {
            text: "Форма обучения",
            dataField: "foId",
            variableName: 'foId',
            sprDataName: 'sprFo',
            typeVar: "selector",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 22,
        },
        {
            text: "Форма финансирования",
            dataField: "ffId",
            variableName: 'ffId',
            sprDataName: 'sprFf',
            typeVar: "selector",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 23,
        },
        {
            text: "Полная компенсация стоимости обучения за счет организации",
            dataField: "fullCompensation",
            variableName: 'fullCompensation',
            sprDataName: 'sprBool',
            typeVar: "selector",
            dependence: true,
            dependenceDataField: "ffId",
            dependenceValueActive: [4],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 24,
        },
        {
            text: "Примечание к приказу о зачислении/переводе *",
            dataField: "prikaz",
            typeVar: "string",
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
            rules: 'customRules',
            customsRulesArray: [
                v => v != null || 'Значение должно быть выбрано'
            ],
            order: 25,
        },
        {
            text: "Отказ от перевода",
            dataField: "otkaz",
            variableName: 'otkaz',
            sprDataName: 'sprBool',
            typeVar: "selector",
            rules: 0,
            order: 26,
        },
        {
            text: "Примечание",
            dataField: "comment",
            typeVar: "string",
            rules: 0,
            order: 27,
        },
        {
            name: 'Приказ',
            typeVar: "file",
            dataField: "prikazFileId",
            fileType: 'PRIKAZ_FILE_ID',
            order: 28,
            dependence: true,
            dependenceDataField: "prikazAvailable",
            dependenceValueActive: [true],
        }

    ]
}